import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInitializerService } from './app-initializer.service';

import { IonicSelectableModule } from 'ionic-selectable';
import { environment } from 'src/environments/environment.prod';

import { StorageService, Storage } from './storage/storage.service';
import { ApolloLink } from 'apollo-link';
import { NgIdleModule } from '@ng-idle/core';
import { ComponentModule } from './components/components.module';
// import { ServiceWorkerModule } from '@angular/service-worker';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		ApolloModule,
		HttpClientModule,
		HttpLinkModule,
		IonicSelectableModule,
		NgIdleModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		// ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
	],
	providers: [
		StatusBar,
		SplashScreen,
		{
			provide: APP_INITIALIZER,
			useFactory: () =>
				function () {
					const ais = new AppInitializerService(
						AppModule.injector.get(Apollo),
						AppModule.injector.get(HttpClient)
					);
					ais.init();
					return ais;
				},
			deps: [HttpClient],
			multi: true,
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	static injector: Injector;
	config: any;

	constructor(
		injector: Injector,
		apollo: Apollo,
		httpLink: HttpLink,
		http: HttpClient,
		storageService: StorageService
	) {
		AppModule.injector = injector;

		// NOTE: Do not remove console.debug statements here

		http
			.get(`${environment.configUrl}`, {
				headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
			})
			.toPromise()
			.then((data) => {
				const link = httpLink.create({
					uri: data['server'].baseUrl,
				});

				const authLink = new ApolloLink((operation, forward) => {
					const token = storageService.get(Storage.ACCESS_TOKEN);
					operation.setContext({
						headers: {
							Authorization: token ? `Bearer ${token}` : '',
						},
					});

					return forward(operation);
				});

				apollo.create({
					link: authLink.concat(link),
					cache: new InMemoryCache(),
					// stop apollo from caching responses
					defaultOptions: {
						watchQuery: {
							fetchPolicy: 'no-cache',
							errorPolicy: 'ignore',
						},
						query: {
							fetchPolicy: 'no-cache',
							errorPolicy: 'all',
						},
					},
				});
			});
	}
}
