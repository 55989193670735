import { Apollo } from 'apollo-angular';
import { IngestCalendarModel } from './exceptionDate';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';

export class ExceptionDatesApi {
  constructor(private apollo: Apollo) {
  }

  create(ingestCalendar: IngestCalendarModel, sources: string[]) {
    const mutation = gql`
      mutation createExceptionDate(
        $exceptionDate: Int!
        $reason: String!
        $sources: [ID]!
      ) {
        createExceptionDate(
          input: {
            exceptionDate: $exceptionDate,
            reason: $reason,
            sources: $sources
          }
        ) {
          id
          created
          exceptionDate
          reason
          sources {
            id
            code
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          exceptionDate: ingestCalendar.exceptionDate,
          reason: ingestCalendar.reason,
          sources
        },
        mutation
      }).toPromise();
  }

  update(ingestCalendar: IngestCalendarModel, sources: string[]) {
    const mutation = gql`
      mutation updateExceptionDate(
        $id: ID!
        $exceptionDate: Int!
        $reason: String!
        $sources: [ID]!
      ) {
        updateExceptionDate(
          input: {
            id: $id,
            exceptionDate: $exceptionDate,
            reason: $reason,
            sources: $sources
          }
        ) {
          id
          created
          exceptionDate
          reason
          sources {
            id
            code
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: ingestCalendar.id,
          reason: ingestCalendar.reason,
          exceptionDate: ingestCalendar.exceptionDate,
          sources
        },
        mutation
      }).toPromise();
  }

  delete(ingestCalendar: IngestCalendarModel) {
    const mutation = gql`
      mutation deleteExceptionDate(
        $id: ID!
      ) {
        deleteExceptionDate(
          id: $id
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: ingestCalendar.id
        },
        mutation
      }).toPromise();
  }

  get(id: string) {
    const query = gql`
    query getExceptionDate (
      $id: ID!
    ) {
       exceptionDate(
         id: $id
        ) {
          id
          created
          exceptionDate
          reason
          sources {
            id
            code
          }
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          id
        },
        query
      }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listExceptionDates(
        $max: Int!
        $offset: Int!
        $sort: String!
      ) {
        exceptionDates(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on IngestCalendar {
              id
              created
              updated
              exceptionDate
              reason
              sources {
                id
                code
              }
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'date:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }
}
