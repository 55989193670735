import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, Events } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../users/user.service';
import { Config } from 'src/app/config';
import waitUntil from 'async-wait-until';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private idle: Idle,
    private router: Router,
    private storageService: StorageService,
    private toastController: ToastController,
    private translate: TranslateService,
    private userService: UserService,
    private event: Events
  ) { }

  private isInitial: boolean = true;

  logout() {
    this.userService.logout().then((data: any) => {
      this.stopIdle();
      this.storageService.clear();
      this.event.publish('sessionExpired');
      this.error(this.translate.instant('ui.error.session.expired'));
      this.router.navigateByUrl('/login');
    }, errors => {
      if (errors.graphQLErrors !== null) {
        const errorsList: any[] = errors.graphQLErrors;

        if (errorsList.length !== 0) {
          switch (errorsList[0].extensions.status) {
            case 401: {
              this.error(this.translate.instant('ui.error.invalid.token'));
              break;
            }
            default: {
              this.error(this.translate.instant('ui.error.standard'));
            }
          }
        } else {
          this.error(this.translate.instant('ui.error.standard'));
        }
      } else {
        this.error(this.translate.instant('ui.error.standard'));
      }

      this.router.navigateByUrl('/login');
    });
  }

  async startIdle() {
    await waitUntil(() => Config.session() !== undefined, {timeout: 5000});

    this.idle.setIdle(Config.session().idle);
    this.idle.setTimeout(Config.session().timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    if (this.isInitial) {
      this.idle.onIdleStart.subscribe(() => {
        console.log('User is now idle. -------------');
      });

      this.idle.onIdleEnd.subscribe(() => {
        console.log('User is now active. -----------');
      });

      this.idle.onTimeout.subscribe(() => {
        this.logout();
      });

      this.isInitial = false;
    }

    this.idle.watch();
  }

  stopIdle() {
    this.idle.clearInterrupts();
    this.idle.stop();
  }

  async error(message: string) {
    this.toast(message, 'danger');
  }

  async toast(message: string, type: string) {
    const toast = await this.toastController.create({
      message,
      showCloseButton: true,
      duration: 5000,
      position: 'top',
      color: type
    });

    toast.present();
  }
}
