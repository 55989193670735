import { ApiClient } from './api/api-client';

export const navbarDateTimeFormat = 'dddd, MMM D, YYYY HH:mm:ss';
export const dateTimeFormat = 'MMM D, YYYY HH:mm';
export const ingestDateFormat = 'MMM D, YYYY';
export const timeFormat = 'HH:mm';

async function getConfig() {
  const request = await fetch('/config.json', {
    method: 'GET'
  });

  return await request.json();
}

export { getConfig };

export abstract class Model {
  // tslint:disable-next-line:variable-name
  private static _api;

  id: string;
  created: Date;
  updated: Date;

  static setApiClient(arg: ApiClient) {
    this._api = arg;
  }

  static api(): ApiClient {
    return this._api;
  }
}
