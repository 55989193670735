import { Apollo } from 'apollo-angular';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';

export class RelationshipsApi {
  constructor(private apollo: Apollo) {
  }

  list(opts: ListOpts) {
    const query = gql`
      query listRelationships(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        relationships(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on Relationship {
              id
              code
              description
            }
          }
        }
      }
    `;

    let max = 17;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }
}
