import { query } from "@angular/animations";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { ApprovalMatrixModel } from "./approval-matrix/approval-matrix";
import { RPSConfigModel, Configs } from "./config-table/config-table";
import { RPConfigModel, RPConfigOverAllModel } from "./rp-decision-table/rp-decision-table";

export class ConfigTableApi {
  constructor(private apollo: Apollo) {}

  getRPSConfig() {
    const query = gql`
      query listConfig {
        listConfig {
          __typename
          ... on Config {
            id
            key
            value
            description
            type
          }
        }
      }
    `;
    return this.apollo
      .query({
        query,
      })
      .toPromise();
  }

  updateRPSConfig(config: RPSConfigModel) {
    const mutation = gql`
      mutation updateConfig(
        $id: ID
        $key: String!
        $value: String!
        $type: String!
        $description: String!
      ) {
        updateConfig(
          input: { id: $id, key: $key, value: $value, type: $type description: $description }
        ) {
          id
          key
          value
          type
          description
        }
      }
    `;
    return this.apollo
      .mutate({
        variables: {
          id: config.id,
          key: config.key,
          value: config.value,
          type: config.type,
          description: config.description
        },
        mutation,
      })
      .toPromise();
  }

  getRPTable() {
    const query = gql`
      query listRpDecision {
        listRpDecision {
          __typename
          ... on RPDecision {
            id
            counter
            query
            classification
            tag
          }
        }
      }
    `;
    return this.apollo
      .query({
        query,
      })
      .toPromise();
  }

  updateRPTable(config: RPConfigModel) {
    const mutation = gql`
      mutation updateRpDecision(
        $id: ID
        $counter: Int!
        $query: String!
        $classification: String
        $tag: String
      ) {
        updateRpDecision(
          input: {
            id: $id
            counter: $counter
            query: $query
            classification: $classification
            tag: $tag
          }
        ) {
          id
          counter
          query
          classification
          tag
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: config.id,
          counter: config.counter,
          query: config.query,
          classification: config.classification,
          tag: config.tag,
        },
        mutation,
      })
      .toPromise();
  }

  getRPTableOverall() {
    const query = gql`
      query listRpOverallIdentification {
        listRpOverallIdentification {
          __typename
          ... on RPOverallIdentification {
            id
            counter
            query
          }
        }
      }
    `;

    return this.apollo.query({ query }).toPromise();
  }

  updateRPTableOverall(config: RPConfigOverAllModel){
      const mutation = gql`mutation updateRpOverallIdentification(
        $id: ID
        $counter: Int!
        $query: String!
    ) {
        updateRpOverallIdentification(
            input: {
                id: $id
                counter: $counter
                query: $query
            }
        ) {
            id
            counter
            query
        }
    }`;
    return this.apollo.mutate({
        variables: {
            id: config.id,
            counter: config.counter,
            query: config.query
        }, mutation
    }).toPromise()
  }

  getApprovalMatrix(){
      const query = gql`query listApprovalMatrix {
        listApprovalMatrix {
            __typename
            ... on ApprovalMatrix {
                id
                priority
                op
                amount
                productPrefix
                productOp
                productType
                rpTag
                designatedApprover
                rptc
                bod
            }
        }
    }`
    return this.apollo.query({query}).toPromise()
  }

  updateApprovalMatrix(config: ApprovalMatrixModel){
      const mutation = gql`mutation updateApprovalMatrix(
        $id: ID
        $priority: Int!
        $op: String!
        $amount: Float!
        $productPrefix: String
        $productType: String
        $productOp: String
        $rpTag: String
        $designatedApprover: Boolean
        $rptc: Boolean
        $bod: Boolean
    ) {
        updateApprovalMatrix(
            input: {
                id: $id
                priority: $priority
                op: $op
                amount: $amount
                productPrefix: $productPrefix
                productOp: $productOp
                productType: $productType
                rpTag: $rpTag
                designatedApprover: $designatedApprover
                rptc: $rptc
                bod: $bod
            }
        ) {
            id
            priority
            op
            amount
            productPrefix
            productOp
            productType
            rpTag
            designatedApprover
            rptc
            bod
        }
    }`

    return this.apollo.mutate({
        variables: {
            id: config.id,
            priority: config.priority,
            op: config.op,
            amount: config.amount,
            productPrefix: config.productPrefix,
            productType: config.productType,
            productOp: config.productOp,
            rpTag: config.rpTag,
            designatedApprover: config.designatedApprover,
            rptc: config.rptc,
            bod: config.bod
        }, mutation
    }).toPromise()
  }
}
