import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Model } from './model';
import { ApiClient } from './api/api-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config, ServerConfigModel, SessionConfigModel } from './config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) { }

  init() {
    this.getConfig().toPromise()
      .then((data: any) => {
        Config.setServer(data.server as ServerConfigModel);
        Config.setSession(data.session as SessionConfigModel);
        Config.setVersion(data.version);
        Model.setApiClient(new ApiClient(this.apollo));
      });
  }

  private getConfig() {
    return this.http.get(`${environment.configUrl}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    });
  }
}
