import { Component } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { StorageService, Storage } from './storage/storage.service';
import { SessionService } from './session/session.service';
import { environment } from 'src/environments/environment.prod';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
// import { SwUpdate } from '@angular/service-worker';

export let IsConnected:ConnectionState;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private connectionService: ConnectionService,
    private translate: TranslateService,
    private storageService: StorageService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private http: HttpClient,
    private sessionService: SessionService,
    // private updates: SwUpdate,
    private toastController: ToastController
  ) {
    this.initializeApp();

    this.connectionService.monitor().subscribe(isConnected => {
      IsConnected = isConnected;
      if (IsConnected) {
        this.success('Connection Restored');
      } else {
        this.error('Connection Unavailable');
      }
    })

    // updates.available.subscribe(event => {
    //   this.update('A new update is now available. Please reload the page.', 'success');
    // });
  }

  config: any;

  initializeApp() {
    this.translate.setDefaultLang('en');

    if (this.storageService.get(Storage.ACCESS_TOKEN) != null) {
      this.sessionService.startIdle();
    } else {
      this.sessionService.stopIdle();
    }
  }

  async update(message: string, type: string) {
    const toast = await this.toastController.create({
      message,
      showCloseButton: true,
      duration: 0,
      position: 'top',
      color: type,
      buttons: [{
        text: "Reload",
        handler: () => {
          document.location.reload();
        }
      }]
    });

    toast.present();
  }

  async success(message: string) {
    this.toast(message, 'success');
  }

  async error(message: string) {
    this.toast(message, 'danger');
  }

  async toast(message: string, type: string) {
    const toast = await this.toastController.create({
      message,
      showCloseButton: true,
      duration: 0,
      position: 'top',
      color: type
    });

    toast.present();
  }
}
