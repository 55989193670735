import { Apollo } from 'apollo-angular';
import { ListOpts } from '../../api/list-opts';
import gql from 'graphql-tag';

export class ProductTypesApi {
  constructor(private apollo: Apollo) {
  }

  list(opts: ListOpts) {
    const query = gql`
      query productTypes(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        productTypes (
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on ProductType {
              id
              code
              description
              type
            }
          }
        }
      }
    `;

    let max = 40;
    let offset = 0;
    let sort = 'description:asc';

    if (opts.max === 0)
      max = opts.max;
    else if (opts.max)
      max = opts.max;

    if (opts.offset)
      offset = opts.offset;

    if (opts.sort)
      sort = opts.sort;

    return this.apollo.query({
      variables: {
        max,
        offset,
        sort,
      },
      query
    }).toPromise();
  }
}
