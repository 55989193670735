import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
	},
	{
		path: '401',
		loadChildren: () => import('./pages/errors/errors-401/errors-401.module').then(m => m.Errors401PageModule),
	},
	{
		path: '403',
		loadChildren: () => import('./pages/errors/errors-403/errors-403.module').then(m => m.Errors403PageModule),
	},
	{
		path: '404',
		loadChildren: () => import('./pages/errors/errors-404/errors-404.module').then(m => m.Errors404PageModule),
	},
	{
		path: '**',
		redirectTo: '/404',
	},

	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
