import { Model } from '../model';
import { ListOpts } from '../api/list-opts';
import { GroupModel } from '../groups/group';

export const emailRegex = '^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const usernameRegex = '^[\\S]+$';

export class User extends Model {
  static create(user: UserModel) {
    return this.api().users().create(user);
  }

  static delete(user: UserModel) {
    return this.api().users().delete(user);
  }

  static get(id: string) {
    return this.api().users().get(id);
  }

  static list(opts: ListOpts) {
    return this.api().users().list(opts);
  }

  static listBusinessUnits(opts: ListOpts) {
    return this.api().users().listBusinessUnits(opts);
  }

  static login(domain: string, username: string, password: string) {
    return this.api().users().login(domain, username, password);
  }

  static logout() {
    return this.api().users().logout();
  }

  static search(id: string) {
    return this.api().users().search(id);
  }

  static update(user: UserModel) {
    return this.api().users().update(user);
  }

  static validateToken() {
    return this.api().users().validateToken();
  }

  static validateUser(domain: string, userId: string) {
    return this.api().users().validateUser(domain, userId);
  }
}

export interface UserModel {
  id: string;
  created: number;
  domain: string;
  username: string;
  firstName: string;
  middleName: string;
  lastName: string;
  groupId: string;
  businessUnit: string;
  branch: string;
  status: string;
  remarks: string;
}
