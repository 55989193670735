import { Apollo } from 'apollo-angular';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';
import { PersonModel, PersonSearchParams } from './person';
import { CustomerIndividualAffiliateModel } from 'src/app/relationships/customer-relationships/customer-relationship';
import { CustomerNonIndividualAffiliateModel } from 'src/app/relationships/customer-relationships/customer-relationship';

export class PersonsApi {
  constructor(private apollo: Apollo) {
  }

  create(
    person: PersonModel,
    indAfflList: CustomerIndividualAffiliateModel[],
    nonIndAfflList: CustomerNonIndividualAffiliateModel[]
  ) {
    // TODO: Optimize
    const relationships = [];

    indAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      relationships.push({
        destination: rel.person.id,
        relationship: rel.relationship.id,
        attributes
      });
    });

    nonIndAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          source: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          destination: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    const mutation = gql`
      mutation createPerson(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $birthday: Long
        $remarks: String
        $tin: String
        $cif: String
        $logicalBranchCode: String
        $relationships: [CreateCustomerRelationship]
        $isRelatedParty: Boolean!
      ) {
        createPerson(
          input: {
            firstName: $firstName,
            middleName: $middleName,
            lastName: $lastName,
            birthday: $birthday,
            remarks: $remarks,
            tin: $tin,
            cif: $cif,
            logicalBranchCode: $logicalBranchCode,
            relationships: $relationships,
            isRelatedParty: $isRelatedParty
          }
        ) {
          id
          firstName
          middleName
          lastName
          birthday
          remarks
          rpClassification {
            code
            description
          }
          rpTag {
            code
            description
          }
          tin
          cif
          logicalBranchCode
          isRelatedParty
          created
          updated
          relationships {
            destination {
              ... on Person {
                firstName
                lastName
              }
            }
            relationship {
              description
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          firstName: person.firstName,
          middleName: person.middleName,
          lastName: person.lastName,
          birthday: person.birthday,
          remarks: person.remarks,
          tin: person.tin,
          cif: person.cif,
          logicalBranchCode: person.logicalBranchCode,
          relationships,
          isRelatedParty: person.isRelatedParty
        },
        mutation
      }).toPromise();
  }

  delete(person: PersonModel) {
    const mutation = gql`
      mutation deletePerson(
        $id: ID!
      ) {
        deletePerson(
          id: $id
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: person.id
        },
        mutation
      }).toPromise();
  }

  get(id: string) {
    const query = gql`
      query getPerson (
        $id: ID!
      ) {
        person(
          id: $id
        ) {
          id
          firstName
          middleName
          lastName
          birthday
          remarks
          employeeId
          rpClassification {
            code
            description
          }
          rpLastUpdated
          rpTag {
            code
            description
          }
          tin
          cif
          logicalBranchCode
          applications {
            ... on Application {
              id
              created
              updated
              customer {
                ... on Person {
                  id
                  firstName
                  middleName
                  lastName
                }
                ... on Company {
                  id
                  name
                }
              }
              rpTag {
                code
                description
              }
              amount
              applicationReference
              productType {
                code
                description
                type
              }
              status
            }
          }
          relationships {
            id
            source {
              ... on Person {
                id
                firstName
                middleName
                lastName
                rpTag {
                  code
                  description
                }
              }
              ... on Company {
                id
                name
                businessType
                rpTag {
                  code
                  description
                }
                isRoot
              }
            }
            destination {
              ... on Person {
                id
                firstName
                middleName
                lastName
                rpTag {
                  code
                  description
                }
              }
              ... on Company {
                id
                name
                businessType
                rpTag {
                  code
                  description
                }
                isRoot
              }
            }
            relationship {
              id
              code
              description
            }
            attributes {
              ... on BooleanRelAttr {
                key
                booleanValue
              }
              ... on DateTimeRelAttr {
                key
                dateTimeValue
              }
              ... on NumberRelAttr {
                key
                numberValue
              }
              ... on StringRelAttr {
                key
                stringValue
              }
            }
          }
          isRelatedParty
          ocsTag
          icbsTag
          essTag
          created
          updated
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          id
        },
        query
      }).toPromise();
  }

  getRPTag(id: string) {
    const query = gql`
      query getPerson (
        $id: ID!,
        $showLog: Boolean
      ) {
        person(
          id: $id,
          showLog: $showLog
        ) {
          id
          rpTag {
            code
            description
          }
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          id,
          showLog: false
        },
        query
      }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listPersons(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        persons(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on Person {
              id
              firstName
              middleName
              lastName
              birthday
              tin
              logicalBranchCode
              rpTag {
                code
                description
              }
              rpLastUpdated
              created
              updated
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }

  search(params: PersonSearchParams, opts: ListOpts) {
    const query = gql`
      query searchPerson(
        $firstName: String!
        $middleName: String
        $lastName: String!
        $birthday: Long
        $tin: String
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        searchPerson(
          input: {
            firstName: $firstName,
            middleName: $middleName,
            lastName: $lastName,
            birthday: $birthday,
            tin: $tin
          },
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          id
          firstName
          middleName
          lastName
          birthday
          remarks
          rpClassification {
            code
            description
          }
          rpTag {
            code
            description
          }
          tin
          cif
          logicalBranchCode
          ocsTag
          icbsTag
          essTag
          rpLastUpdated
          created
          updated
          relationships {
            id
            destination {
              ... on Person {
                id
                firstName
                middleName
                lastName
              }
              ... on Company {
                id
                name
                businessType
              }
            }
            relationship {
              id
              code
              description
            }
            attributes {
              ... on BooleanRelAttr {
                key
                booleanValue
              }
              ... on DateTimeRelAttr {
                key
                dateTimeValue
              }
              ... on NumberRelAttr {
                key
                numberValue
              }
              ... on StringRelAttr {
                key
                stringValue
              }
            }
          }
        }
      }
    `;

    let max = 20;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          firstName: params.firstName,
          middleName: params.middleName,
          lastName: params.lastName,
          birthday: params.birthday,
          tin: params.tin,
          max,
          offset,
          sort
        },
        query
      }).toPromise();
  }

  update(
    person: PersonModel,
    indAfflList: CustomerIndividualAffiliateModel[],
    nonIndAfflList: CustomerNonIndividualAffiliateModel[]
  ) {
    // TODO: Optimize
    const relationships = [];

    indAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      relationships.push({
        id: rel.id,
        destination: rel.person.id,
        relationship: rel.relationship.id,
        attributes
      });
    });

    nonIndAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          id: rel.id,
          source: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          id: rel.id,
          destination: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    const mutation = gql`
      mutation updatePerson(
        $id: ID!
        $firstName: String!
        $middleName: String
        $lastName: String!
        $birthday: Long
        $remarks: String
        $tin: String
        $cif: String
        $logicalBranchCode: String
        $relationships: [UpdateCustomerRelationship]
        $isRelatedParty: Boolean!
      ) {
        updatePerson(
          input: {
            id: $id,
            firstName: $firstName,
            middleName: $middleName,
            lastName: $lastName,
            birthday: $birthday,
            remarks: $remarks,
            tin: $tin,
            cif: $cif,
            logicalBranchCode: $logicalBranchCode,
            relationships: $relationships,
            isRelatedParty: $isRelatedParty
          }
        ) {
          id
          firstName
          middleName
          lastName
          birthday
          remarks
          rpClassification {
            code
            description
          }
          rpTag {
            code
            description
          }
          tin
          cif
          logicalBranchCode
          relationships {
            id
            destination {
              ... on Person {
                id
                firstName
                middleName
                lastName
              }
              ... on Company {
                id
                name
              }
            }
            relationship {
              id
              description
            }
          }
          created
          updated
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: person.id,
          firstName: person.firstName,
          middleName: person.middleName,
          lastName: person.lastName,
          birthday: person.birthday,
          remarks: person.remarks,
          tin: person.tin,
          cif: person.cif,
          logicalBranchCode: person.logicalBranchCode,
          relationships,
          isRelatedParty: person.isRelatedParty
        },
        mutation
      }).toPromise();
  }
}
