import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ListOpts } from '../api/list-opts';
import { ApiKeyModel } from './apiKey';

export class ApiKeysApi {
  constructor(private apollo: Apollo) {}

  create(apiKey: ApiKeyModel) {
    const mutation = gql`
      mutation createApiKey($name: String!) {
        createApiKey(name: $name) {
          id
          name
          accessKey
          secretKey
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        name: apiKey.name
      },
      mutation
    }).toPromise();
  }

  delete(id: string) {
    const mutation = gql`
      mutation deleteApiKey($id: ID!) {
        deleteApiKey(id: $id) {
          status
          code
          message
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        id
      },
      mutation
    }).toPromise();
  }

  get(id: string) {
    const query = gql`
      query getApiKey($id: ID!) {
        apiKey(id: $id) {
          id
          created
          updated
          name
          accessKey
          secretKey
        }
      }
    `;

    return this.apollo.query({
      variables: {
        id
      },
      query
    }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listApiKeys(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        apiKeys (
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on ApiKey {
              id
              created
              updated
              name
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'created:desc';

    if (opts.max === 0)
      max = opts.max;
    else if (opts.max)
      max = opts.max;

    if (opts.offset)
      offset = opts.offset;

    if (opts.sort)
      sort = opts.sort;

    return this.apollo.query({
      variables: {
        max,
        offset,
        sort,
      },
      query
    }).toPromise();
  }

  regenerateAccessKey(id: string) {
    const mutation = gql`
      mutation regenerateAccessKey($id: ID!) {
        regenerateAccessKey(id: $id) {
          id
          created
          updated
          name
          accessKey
          secretKey
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        id
      },
      mutation
    }).toPromise();
  }

  regenerateSecretKey(id: string) {
    const mutation = gql`
      mutation regenerateSecretKey($id: ID!) {
        regenerateSecretKey(id: $id) {
          id
          created
          updated
          name
          accessKey
          secretKey
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        id
      },
      mutation
    }).toPromise();
  }
}
