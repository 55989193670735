export class Config {
  private static serverConfig: ServerConfigModel;
  private static sessionConfig: SessionConfigModel;
  private static versionConfig: string;

  static setServer(arg: ServerConfigModel) {
    this.serverConfig = arg;
  }

  static setSession(arg: SessionConfigModel) {
    this.sessionConfig = arg;
  }

  static setVersion(arg: string) {
    this.versionConfig = arg;
  }

  static server(): ServerConfigModel {
    return this.serverConfig;
  }

  static session(): SessionConfigModel {
    return this.sessionConfig;
  }

  static version(): string {
    return this.versionConfig;
  }
}

export interface ServerConfigModel {
  baseUrl: string;
}

export interface SessionConfigModel {
  idle: number;
  timeout: number
}
