import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ListOpts } from '../api/list-opts';
import { GroupModel } from './group';

export class GroupsApi {
  constructor(private apollo: Apollo) {
  }

  create(group: GroupModel, roles: string[]) {
    const mutation = gql`
      mutation createGroup(
        $name: String!
        $roles: [ID]
      ) {
        createGroup(
          input: {
            name: $name,
            roles: $roles
          }
        ) {
          id
          name
          roles {
            id
            code
            description
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          name: group.name,
          roles
        },
        mutation
      }).toPromise();
  }

  delete(group: GroupModel) {
    const mutation = gql`
      mutation deleteGroup(
        $id: ID!
      ){
        deleteGroup(
          id: $id
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: group.id
        },
        mutation
      }).toPromise();
  }

  get(id: string) {
    const query = gql`
      query getGroup (
        $id: ID!
      ) {
        group(
          id: $id
        ) {
          id
          name
          roles {
            ... on Role {
              code
            }
          }
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          id
        },
        query
      }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listGroups(
        $max: Int,
        $offset: Int,
        $sort: String
      ) {
        groups(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on Group {
              id
              name
              created
              updated
              roles {
                id
                code
                description
              }
            }
          }
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          max: opts.max,
          offset: opts.offset,
          sort: opts.sort,
        },
        query
      }).toPromise();
  }

  search(name: string) {
    const query = gql`
      query searchGroup(
        $name: String!
      ) {
        searchGroup(
          name: $name
        ) {
          id
          name
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          name
        },
        query
      }).toPromise();
  }

  update(group: GroupModel, roles: string[]) {
    const mutation = gql`
      mutation updateGroup(
        $id : ID!
        $name: String!
        $roles: [ID]
      ) {
        updateGroup(
          input: {
            id: $id,
            name: $name,
            roles: $roles
          }
        ) {
          id
          name
          roles {
            id
            code
            description
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: group.id,
          name: group.name,
          roles
        },
        mutation
      }).toPromise();
  }
}
