import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// TODO: Change to sessionStorage if per tab requirement pushes through
export class StorageService {
  clear() {
    localStorage.clear();
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  has(key: string) {
    return (localStorage.getItem(key) !== null);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }
}

export class Storage {
  static ACCESS_TOKEN = 'accessToken';
  static LOGGED_IN = 'loggedIn';
  static ROOT_ID = 'rootId';
  static USER_USERNAME = 'username';
  static USER_NAME = 'name';
  static USER_GROUP = 'group';
  static USER_ROLES = 'roles';
  static USER_SUPERADMIN = 'isSuperAdmin';
}