import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export class RolesApi {
  constructor(private apollo: Apollo) {
  }

  list() {
    const query = gql`
      query listRoles{
        roles {
          ... on Role {
            id
            code
            description
          }
        }
      }
    `;

    return this.apollo
      .query({
        query
      }).toPromise();
  }
}
