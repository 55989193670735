import { Injectable } from '@angular/core';
import { UserModel, User } from './user';
import { GroupModel } from '../groups/group';
import { ListOpts } from '../api/list-opts';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  create(user: UserModel) {
    return User.create(user);
  }

  delete(user: UserModel) {
    return User.delete(user);
  }

  get(id: string) {
    return User.get(id);
  }

  list(opts: ListOpts) {
    return User.list(opts);
  }

  listBusinessUnits(opts: ListOpts) {
    return User.listBusinessUnits(opts);
  }

  login(domain: string, username: string, password: string) {
    return User.login(domain, username, password);
  }

  logout() {
    return User.logout();
  }

  search(id: string) {
    return User.search(id);
  }

  update(user: UserModel) {
    return User.update(user);
  }

  validateToken() {
    return User.validateToken();
  }

  validateUser(domain: string, userId: string) {
    return User.validateUser(domain, userId);
  }
}
