import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ApplicationStatus } from './application-status';
import { $enum } from 'ts-enum-util';
import { UserStatus } from './user-status';

export class ReportsApi {
  constructor(private apollo: Apollo) {
  }

  extractAggregateBreachReport(applicationId: string) {
    const query = gql`

      query extractAggregateBreachReport (
        $applicationId: ID!
      ) {
        extractAggregateBreachReport(
          input: {
            applicationId: $applicationId
          }
        ) {
          column1
          column2
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          applicationId
        },
        query
      }).toPromise();
  }

  extractAnnualNonMaterialRPTReportDOSRISA(fromDate: number, toDate: number) {
    const query = gql`
      query extractAnnualNonMaterialRPTReportDOSRISA (
        $fromDate: Int!
        $toDate: Int!
      ) {
        extractAnnualNonMaterialRPTReportDOSRISA(
          input: {
            fromDate: $fromDate,
            toDate: $toDate
          }
        ) {
          column1
          column2
          column3
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate
        },
        query
      }).toPromise();
  }

  extractAnnualNonMaterialRPTReportORPNC(fromDate: number, toDate: number) {
    const query = gql`
      query extractAnnualNonMaterialRPTReportORPNC (
        $fromDate: Int!
        $toDate: Int!
      ) {
        extractAnnualNonMaterialRPTReportORPNC(
          input: {
            fromDate: $fromDate,
            toDate: $toDate
          }
        ) {
          column1
          column2
          column3
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate
        },
        query
      }).toPromise();
  }

  extractDedupExceptionReport(fromDate: number, toDate: number) {
    const query = gql`
      query extractDedupExceptionReport (
          $fromDate: Int!
          $toDate: Int!
      ) {
        extractDedupExceptionReport(
          input: {
            fromDate: $fromDate,
            toDate: $toDate
          }
        ) {
          column1
          column2
          column3
          column4
          column5
          column6
          column7
          column8
          column9
          column10
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate
        },
        query
      }).toPromise();
  }

  extractGroupProfileEntitlementReport() {
    const query = gql`
      {
        extractGroupProfileEntitlementReport {
          column1
          column2
        }
      }
    `;

    return this.apollo
      .query({
        query
      }).toPromise();
  }

  extractMatchDisparityReport(fromDate: number, toDate: number) {
    const query = gql`
        query extractMatchDisparityReport (
          $fromDate: Int!
          $toDate: Int!
        )
        {
          extractMatchDisparityReport(
            input: {
              fromDate: $fromDate,
              toDate: $toDate
            }
          )
          {
            column1
            column2
            column3
            column4
            column5
            column6
            column7
            column8
            column9
            column10
            column11
            column12
            column13
            column14
          }
        }
    `;

    return this.apollo
    .query({
      variables: {
        fromDate,
        toDate
      },
      query
    }).toPromise();
  }

  extractMonthlyMaterialRPTReport(fromDate: number, toDate: number, status: string) {
    const query = gql`
      query extractMonthlyMaterialRPTReport (
        $fromDate: Int!
        $toDate: Int!
        $status: ApplicationStatus!
      ) {
        extractMonthlyMaterialRPTReport(
          input: {
            fromDate: $fromDate,
            toDate: $toDate,
            status: $status
          }
        ) {
          column1
          column2
          column3
          column4
          column5
          column6
          column7
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate,
          status: $enum(ApplicationStatus).getKeyOrDefault(status)
        },
        query
      }).toPromise();
  }

  extractMonthlyOutstandingCreditExposures(endDate: number, netWorth: number, loanPortfolio: number) {
    const query = gql`
      query extractMonthlyOutstandingCreditExposures (
        $endDate: Int!
        $netWorth: Float!
        $loanPortfolio: Float!
      ) {
        extractMonthlyOutstandingCreditExposures(
          input: {
            endDate: $endDate,
            netWorth: $netWorth,
            loanPortfolio: $loanPortfolio
          }
        ) {
          column1
          column2
          column3
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          endDate,
          netWorth,
          loanPortfolio
        },
        query
      }).toPromise();
  }

  extractQuarterlyMaterialRPTReport(fromDate: number, toDate: number) {
    const query = gql`
      query extractQuarterlyMaterialRPTReport (
        $fromDate: Int!
        $toDate: Int!
      ) {
        extractQuarterlyMaterialRPTReport(
          input: {
            fromDate: $fromDate,
            toDate: $toDate
          }
        ) {
          column1
          column2
          column3
          column4
          column5
          column6
          column7
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate
        },
        query
      }).toPromise();
  }

  extractRPDailyChangeReport(fromDate: number, toDate: number) {
    const query = gql`
      query extractRPDailyChangeReport (
        $fromDate: Int!
        $toDate: Int!
      ) {
        extractRPDailyChangeReport(
          input: {
            fromDate: $fromDate,
            toDate: $toDate
          }
        ) {
          total
          data {
            ... on RPDailyChangeReport {
              source
              customerType
              firstName
              middleName
              lastName
              name
              birthday
              logicalBranchCode
              cif
              rpTag
              systemAction
              actionDate
            }
          }
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          fromDate,
          toDate
        },
        query
      }).toPromise();
  }

  extractUserEntitlementReport(userStatus: string) {
    const query = gql`
      query extractUserEntitlementReport (
        $userStatus: String!
      ) {
        extractUserEntitlementReport(
          input: {
            userStatus: $userStatus
          }
        ) {
          column1
          column2
          column3
          column4
          column5
          column6
          column7
          column8
          column9
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          userStatus: $enum(UserStatus).getKeyOrDefault(userStatus)
        },
        query
      }).toPromise();
  }

  extractOutstandingNonCreditExposureReport(fromDate: number, toDate: number) {
    const query = gql`
    query extractOutstandingNonCreditExposureReport (
      $fromDate: Int!
      $toDate: Int!
    ) {
      extractOutstandingNonCreditExposureReport(
        input: {
          fromDate: $fromDate,
          toDate: $toDate
        }
      ) {
        column1
        column2
      }
    }
    `;

    return this.apollo
    .query({
      variables: {
        fromDate,
        toDate
      },
      query
    }).toPromise();
  }

  extractTrxIngestExceptionReport(ingestedDate: number) {
    const query = gql`
        query extractTrxIngestExceptionReport (
          $ingestedDate: Int!
        )
        {
          extractTrxIngestExceptionReport(
            input: {
              ingestedDate: $ingestedDate
            }
          )
          {
            column1
            column2
            column3
            column4
            column5
            column6
            column7
            column8
            column9
            column10
            column11
            column12
          }
        }
    `;

    return this.apollo
    .query({
      variables: {
        ingestedDate
      },
      query
    }).toPromise();
  }
}
