import { Apollo } from 'apollo-angular';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';

export class IngestApi {
  constructor(private apollo: Apollo) {
  }

  getLastRPIngest() {
    const query = gql`
      {
        getLastRPIngest {
          id
          ingestStatus
          ingestedDate
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  getLastTrxIngest() {
    const query = gql`
      {
        getLastTrxIngest {
          id
          ingestStatus
          ingestedDate
          startDate
          endDate
          hasException
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  getLastSuccessfulRPIngest() {
    const query = gql`
      {
        getLastSuccessfulRPIngest {
          id
          ingestStatus
          ingestedDate
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  getLastSuccessfulTrxIngest() {
    const query = gql`
      {
        getLastSuccessfulTrxIngest {
          id
          ingestStatus
          ingestedDate
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  getRPInProgressIngest() {
    const query = gql`
      {
        getRPInProgressIngest {
          id
          ingestStatus
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  getTrxInProgressIngest() {
    const query = gql`
      {
        getTrxInProgressIngest {
          id
          ingestStatus
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .query({
        variables: {},
        query
      }).toPromise();
  }

  listRPIngestErrorLogs(opts: ListOpts) {
    const query = gql`
      query listRPIngestErrorLogs(
        $max: Int!,
        $offset: Int!,
        $sort: String,
      ) {
        listRPIngestErrorLogs(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on RPIngestErrorLog {
              id
              created
              updated
              message
              record
              filename
              errorDate
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort
        },
        query
      }).toPromise();
  }

  listTrxIngestErrorLogs(opts: ListOpts) {
    const query = gql`
      query listTrxIngestErrorLogs(
        $max: Int!,
        $offset: Int!,
        $sort: String,
      ) {
        listTrxIngestErrorLogs(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on TrxIngestErrorLog {
              id
              created
              updated
              filename
              message
              record
              errorDate
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort
        },
        query
      }).toPromise();
  }

  listRPIngestLogs(opts: ListOpts) {
    const query = gql`
      query listRPIngestLogs(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        listRPIngestLogs(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on RPIngestLog {
              id
              created
              updated
              startDate
              endDate
              ingestStatus
              message
              username
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }

  listTrxIngestLogs(opts: ListOpts) {
    const query = gql`
      query listTrxIngestLogs(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        listTrxIngestLogs(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on TrxIngestLog {
              id
              created
              updated
              startDate
              endDate
              ingestStatus
              message
              username
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }

  startRPIngest() {
    const mutation = gql`
      mutation startRPIngest {
        startRPIngest {
          id
          ingestStatus
          startDate
          endDate
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {},
        mutation
      }).toPromise();
  }

  startTrxIngest() {
    const mutation = gql`
      mutation startTrxIngest {
        startTrxIngest {
          id
          ingestStatus
          startDate
          endDate
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {},
        mutation
      }).toPromise();
  }

  stopRPIngest() {
    const mutation = gql`
      mutation stopRPIngest {
        stopRPIngest {
          id
          ingestStatus
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {},
        mutation
      }).toPromise();
  }

  stopTrxIngest() {
    const mutation = gql`
      mutation stopTrxIngest {
        stopTrxIngest {
          id
          ingestStatus
          startDate
          endDate
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {},
        mutation
      }).toPromise();
  }
}
