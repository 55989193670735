import { Apollo } from 'apollo-angular';
import { UserModel } from './user';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';
import { GroupModel } from '../groups/group';

export class UsersApi {
  constructor(private apollo: Apollo) {
  }

  create(user: UserModel) {
    const mutation = gql`
      mutation createUser(
        $domain: String!,
        $username: String!,
        $firstName: String!,
        $middleName: String,
        $lastName: String!,
        $groupId: ID!,
        $businessUnit: String!,
        $branch: String!,
        $remarks: String,
        $status: String!
      ) {
        createUser(
          input: {
            domain: $domain,
            username: $username,
            firstName: $firstName,
            middleName: $middleName,
            lastName: $lastName,
            groupId: $groupId,
            businessUnit: $businessUnit,
            branch: $branch,
            remarks: $remarks,
            status: $status
          }
        ) {
          id
          created
          updated
          domain
          username
          firstName
          middleName
          lastName
          group {
            ... on Group {
              id
              name
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          domain: user.domain,
          username: user.username,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          groupId: user.groupId,
          businessUnit: user.businessUnit,
          branch: user.branch,
          remarks: user.remarks,
          status: user.status
        },
        mutation
      }).toPromise();
  }

  delete(user: UserModel) {
    const mutation = gql`
      mutation deleteUser(
        $id: ID!,
        $remarks: String!
      ) {
        deleteUser(
          input: {
            id: $id,
            remarks: $remarks
          }
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: user.id,
          remarks: user.remarks
        },
        mutation
      }).toPromise();
  }

  get(id: string) {
    const query = gql`
    query getUser (
      $id: ID!
    ) {
       user(
         id: $id
        ) {
          id
          created
          updated
          domain
          username
          firstName
          middleName
          lastName
          isSuperAdmin
          group {
            ... on Group {
              id
              name
            }
          }
          businessUnit {
            id
            code
            branches {
              ... on Branch {
                id
                code
              }
            }
          }
          branch {
            id
            code
          }
          status
          remarks
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          id
        },
        query
      }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listUsers(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        users(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on User {
              id
              created
              updated
              username
              firstName
              middleName
              lastName
              group {
                id
                name
                roles {
                  id
                  code
                  description
                }
              }
              businessUnit {
                id
                code
              }
              branch {
                id
                code
              }
              status
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'created:desc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }

  listBusinessUnits(opts: ListOpts) {
    const query = gql`
      query listBusinessUnits(
        $sort: String!
      ) {
        businessUnits(
          opts: {
            sort: $sort
          }
        ) {
          ... on BusinessUnit {
            id
            code
            branches {
              ... on Branch {
                id
                code
              }
            }
          }
        }
      }
    `;

    let sort = 'created:desc';
    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          sort
        },
        query
      }).toPromise();
  }

  login(domain, username, password) {
    const mutation = gql `
      mutation login(
        $domain: String!
        $username: String!
        $password: String!
      ) {
        login(
          domain: $domain,
          username: $username,
          password: $password
        ) {
          token
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          domain,
          username,
          password
        },
        mutation
      }).toPromise();
  }

  logout() {
    const mutation = gql `
      mutation logout {
        logout {
          status
          message
          code
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation
      }).toPromise();
  }

  search(username: string) {
    const query = gql`
      query searchUser (
        $username: String!
      ) {
        searchUser(
          username: $username
        ) {
          id
          created
          updated
          username
          firstName
          middleName
          lastName
          group {
            id
            name
            roles {
              id
              code
              description
            }
          }
          businessUnit {
            id
            code
          }
          branch {
            id
            code
          }
          status
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          username
        },
        query
      }).toPromise();
  }

  update(user: UserModel) {
    const mutation = gql`
      mutation updateUser(
        $id: ID!
        $domain: String!
        $username: String!
        $firstName: String!
        $middleName: String
        $lastName: String!
        $groupId: ID!
        $businessUnit: String!
        $branch: String!
        $remarks: String
        $status: String!
      ) {
        updateUser(
          input: {
            id: $id,
            domain: $domain,
            username: $username,
            firstName: $firstName,
            middleName: $middleName,
            lastName: $lastName,
            groupId: $groupId,
            businessUnit: $businessUnit,
            branch: $branch,
            remarks: $remarks,
            status: $status
          }
        ) {
          id
          created
          updated
          username
          firstName
          middleName
          lastName
          group {
            id
            name
          }
          businessUnit {
            code
          }
          branch {
            code
          }
          status
          remarks
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: user.id,
          domain: user.domain,
          username: user.username,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          groupId: user.groupId,
          businessUnit: user.businessUnit,
          branch: user.branch,
          remarks: user.remarks,
          status: user.status
        },
        mutation
      }).toPromise();
  }

  validateToken() {
    const query = gql `
      query validateToken {
        validateToken {
          status
          message
          code
        }
      }
    `;

    return this.apollo
      .query({
        query
      }).toPromise();
  }

  validateUser(domain: string, userId: string) {
    const query = gql `
      query validateUser(
        $domain: String!
        $userId: String!
      ) {
        validateUser(
          domain: $domain,
          userId: $userId
        ) {
          isValid
          firstName
          middleName
          lastName
        }
      }
    `;

    return this.apollo
      .query({
        variables: {
          domain,
          userId
        },
        query
      }).toPromise();
  }
}