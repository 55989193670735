import { CompaniesApi } from '../customers/companies/companies-api';
import { Apollo } from 'apollo-angular';
import { ApplicationsApi } from '../applications/application-api';
import { PersonsApi } from '../customers/persons/persons-api';
import { RelationshipsApi } from '../relationships/relationships-api';
import { UsersApi } from '../users/users-api';
import { IngestApi } from '../ingest/ingest-api';
import { GroupsApi } from '../groups/groups-api';
import { RolesApi } from '../roles/roles-api';
import { DedupExceptionReportApi } from '../reports/dedup-exception-report-api';
import { ProductTypesApi } from '../applications/product-types/product-types-api';
import { ReportsApi } from '../reports/reports-api';
import { ConfigApi } from './config-api';
import { ApiKeysApi } from '../apiKeys/apiKey-api';
import { ExceptionDatesApi } from '../ingestCalendar/exceptionDates-api';
import { ConfigTableApi } from '../components/config-table-api';

export class ApiClient {
  private readonly apiKeysApi;
  private readonly applicationsApi;
  private readonly companiesApi;
  private readonly configApi;
  private readonly dedupExceptionReportApi;
  private readonly exceptionDatesApi;
  private readonly groupsApi;
  private readonly ingestApi;
  private readonly personsApi;
  private readonly productTypesApi;
  private readonly relationshipsApi;
  private readonly reportsApi;
  private readonly rolesApi;
  private readonly usersApi;
  private readonly configTablesApi;

  constructor(apollo: Apollo) {
    this.apiKeysApi = new ApiKeysApi(apollo);
    this.applicationsApi = new ApplicationsApi(apollo);
    this.companiesApi = new CompaniesApi(apollo);
    this.configApi = new ConfigApi(apollo);
    this.dedupExceptionReportApi = new DedupExceptionReportApi(apollo);
    this.exceptionDatesApi = new ExceptionDatesApi(apollo);
    this.ingestApi = new IngestApi(apollo);
    this.groupsApi = new GroupsApi(apollo);
    this.personsApi = new PersonsApi(apollo);
    this.productTypesApi = new ProductTypesApi(apollo);
    this.relationshipsApi = new RelationshipsApi(apollo);
    this.reportsApi = new ReportsApi(apollo);
    this.rolesApi = new RolesApi(apollo);
    this.usersApi = new UsersApi(apollo);
    this.configTablesApi = new ConfigTableApi(apollo)
  }

  apiKeys(): ApiKeysApi {
    return this.apiKeysApi;
  }

  applications(): ApplicationsApi {
    return this.applicationsApi;
  }

  companies(): CompaniesApi {
    return this.companiesApi;
  }

  config(): ConfigApi {
    return this.configApi;
  }

  dedupExceptionReport(): DedupExceptionReportApi {
    return this.dedupExceptionReportApi;
  }

  exceptionDates(): ExceptionDatesApi {
    return this.exceptionDatesApi;
  }

  groups(): GroupsApi {
    return this.groupsApi;
  }

  ingest(): IngestApi {
    return this.ingestApi;
  }

  persons(): PersonsApi {
    return this.personsApi;
  }

  productTypes(): ProductTypesApi {
    return this.productTypesApi;
  }

  relationships(): RelationshipsApi {
    return this.relationshipsApi;
  }

  reports(): ReportsApi {
    return this.reportsApi;
  }

  roles(): RolesApi {
    return this.rolesApi;
  }

  users(): UsersApi {
    return this.usersApi;
  }

  tables(): ConfigTableApi {
    return this.configTablesApi;
  }
}
