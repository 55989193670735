import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export class ConfigApi {
  constructor(private apollo: Apollo) {}

  get(key: string) {
    const query = gql`
      query getConfig(
        $key: String!
      ) {
        getConfig(
          key: $key
        )
      }
    `;

    return this.apollo.query({
      variables: {
        key
      },
      query
    }).toPromise();
  }
}
