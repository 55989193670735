import { ListOpts } from '../api/list-opts';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export class DedupExceptionReportApi {
  constructor(private apollo: Apollo) { }

  list(opts: ListOpts) {
    const query = gql`
      query listDedupExceptionReports(
        $max: Int!,
        $offset: Int!,
        $sort: String,
      ) {
        dedupExceptionReports (
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on DedupExceptionReport {
              id
              created
              updated
              classification
              jaroWinklerScore
              ingest {
                id
              }
              oneSource
              oneFirstName
              oneMiddleName
              oneLastName
              oneName
              oneBirthday
              oneTin
              twoSource
              twoFirstName
              twoMiddleName
              twoLastName
              twoName
              twoBirthday
              twoTin
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max) {
        max = opts.max;
      }

    if (opts.offset) {
        offset = opts.offset;
      }

    if (opts.sort) {
        sort = opts.sort;
      }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }
}
