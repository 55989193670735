import { Apollo } from 'apollo-angular';
import { ListOpts } from 'src/app/api/list-opts';
import gql from 'graphql-tag';
import { CompanyModel, CompanySearchParams } from './company';
import { $enum } from 'ts-enum-util';
import { CustomerIndividualAffiliateModel } from 'src/app/relationships/customer-relationships/customer-relationship';
import { CustomerNonIndividualAffiliateModel } from 'src/app/relationships/customer-relationships/customer-relationship';
import { BusinessType } from '../business-type';
import { AliasModel } from './alias';

export class CompaniesApi {
  constructor(private apollo: Apollo) { }

  create(
    company: CompanyModel,
    indAfflList: CustomerIndividualAffiliateModel[],
    nonIndAfflList: CustomerNonIndividualAffiliateModel[],
    aliasList: AliasModel[]
  ) {
    const relationships = [];

    indAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          source: rel.person.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          destination: rel.person.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    nonIndAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          source: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          destination: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    const mutation = gql`
      mutation createCompany(
        $name: String!
        $tin: String
        $cif: String
        $logicalBranchCode: String
        $businessType: BusinessType!
        $birthday: Long
        $remarks: String
        $isNGORetailFinance: Boolean
        $relationships: [CreateCustomerRelationship]
        $aliases: [CreateAliasesRequest]
        $isRelatedParty: Boolean!
      ) {
        createCompany(
          input: {
            name: $name,
            tin: $tin,
            cif: $cif,
            logicalBranchCode: $logicalBranchCode,
            businessType: $businessType,
            birthday: $birthday,
            remarks: $remarks,
            isNGORetailFinance: $isNGORetailFinance,
            relationships: $relationships,
            aliases: $aliases,
            isRelatedParty: $isRelatedParty
          }
        ) {
          id
          name
          birthday
          businessType
          remarks
          logicalBranchCode
          isRelatedParty
          tin
          cif
          created
          updated
          isNGORetailFinance
          relationships {
            destination {
              ... on Person {
                firstName
                middleName
                lastName
              }
              ... on Company {
                name
              }
            }
            relationship {
              description
            }
          }
          aliases {
            ... on Alias {
              id
              name
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          name: company.name,
          tin: company.tin,
          cif: company.cif,
          birthday: company.birthday,
          isNGORetailFinance: company.isNGORetailFinance,
          logicalBranchCode: company.logicalBranchCode,
          businessType: $enum(BusinessType).getKeyOrDefault(company.businessType),
          remarks: company.remarks,
          relationships,
          aliases: aliasList.map( alias => ({
            name: alias.name
          })),
          isRelatedParty: company.isRelatedParty
        },
        mutation
      }).toPromise();
  }

  delete(company: CompanyModel) {
    const mutation = gql`
      mutation deleteCompany(
        $id: ID!
      ) {
        deleteCompany(
          id: $id
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: company.id
        },
        mutation
      }).toPromise();
  }

  get(id: string) {
    const query = gql`
      query getCompany (
        $id: ID!
      ) {
       company(
         id: $id
        ) {
          id
          created
          updated
          name
          cif
          birthday
          tin
          remarks
          businessType
          rpClassification {
            code
            description
          }
          logicalBranchCode
          rpTag {
            code
            description
          }
          rpLastUpdated
          isNGORetailFinance
          isRoot
          aliases {
            ... on Alias {
              id
              name
            }
          }
          applications {
            ... on Application {
              id
              created
              updated
              applicationReference
              customer {
                ... on Company {
                  id
                  name
                }
              }
              rpTag {
                code
                description
              }
              amount
              productType {
                code
                description
                type
              }
              status
            }
          }
          relationships {
            id
            source {
              ... on Person {
                id
                firstName
                middleName
                lastName
                rpTag {
                  code
                  description
                }
              }
              ... on Company {
                id
                name
                rpTag {
                  code
                  description
                }
                isRoot
              }
            }
            destination {
              ... on Person {
                id
                firstName
                middleName
                lastName
                rpTag {
                  code
                  description
                }
              }
              ... on Company {
                id
                name
                rpTag {
                  code
                  description
                }
                isRoot
              }
            }
            relationship {
              id
              code
              description
            }
            attributes {
              ... on BooleanRelAttr {
                key
                booleanValue
              }
              ... on DateTimeRelAttr {
                key
                dateTimeValue
              }
              ... on NumberRelAttr {
                key
                numberValue
              }
              ... on StringRelAttr {
                key
                stringValue
              }
            }
          }
          created
          updated
          isParentOfRoot
          isRelatedParty
          isRelatedToRoot
          isBSPSupervised
          ocsTag
          icbsTag
          essTag
        }
      }
    `;
    return this.apollo
      .query({
        variables: {
          id
        },
        query
      }).toPromise();
  }

  getCustomerRelationships(id: string, relationship: string, customerType: string, isSource: boolean, opts: ListOpts) {
    const query = gql`
      query customerRelationships (
        $id: ID!
        $relationship: String!
        $customerType: String!
        $isSource: Boolean!
        $max: Int!
        $offset: Int!
        $sort: String!
      ) {
        customerRelationships(
         id: $id,
         relationship: $relationship,
         customerType: $customerType,
         isSource: $isSource,
         opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on CustomerRelationship {
              id
              source {
                ... on Person {
                  id
                  firstName
                  middleName
                  lastName
                  rpTag {
                    code
                    description
                  }
                }
                ... on Company {
                  id
                  name
                  rpTag {
                    code
                    description
                  }
                  isRoot
                }
              }
              destination {
                ... on Person {
                  id
                  firstName
                  middleName
                  lastName
                  rpTag {
                    code
                    description
                  }
                }
                ... on Company {
                  id
                  name
                  rpTag {
                    code
                    description
                  }
                  isRoot
                }
              }
              relationship {
                id
                code
                description
              }
              attributes {
                ... on BooleanRelAttr {
                  key
                  booleanValue
                }
                ... on DateTimeRelAttr {
                  key
                  dateTimeValue
                }
                ... on NumberRelAttr {
                  key
                  numberValue
                }
                ... on StringRelAttr {
                  key
                  stringValue
                }
              }
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          id,
          relationship,
          customerType,
          isSource,
          max,
          offset,
          sort
        },
        query
      }).toPromise();
  }

  getRoot(showLog: boolean) {
    const query = gql`
      query getRoot (
        $showLog: Boolean
      ) {
        rootCompany(
          showLog: $showLog
        ) {
          id
          created
          updated
          name
          cif
          birthday
          tin
          remarks
          businessType
          rpClassification {
            code
            description
          }
          logicalBranchCode
          rpTag {
            code
            description
          }
          rpLastUpdated
          isNGORetailFinance
          isRoot
          aliases {
            ... on Alias {
              id
              name
            }
          }
          applications {
            ... on Application {
              id
              created
              updated
              applicationReference
              customer {
                ... on Company {
                  id
                  name
                }
              }
              rpTag {
                code
                description
              }
              amount
              productType {
                code
                description
                type
              }
              status
            }
          }
          created
          updated
          isParentOfRoot
          isRelatedParty
          isRelatedToRoot
          isBSPSupervised
          ocsTag
          icbsTag
          essTag
        }
      }
    `;
    return this.apollo
      .query({
        variables: {
          showLog: showLog
        },
        query
      }).toPromise();
  }

  getRPTag(id: string) {
    const query = gql`
    query getCompany (
      $id: ID!,
      $showLog: Boolean
    ) {
       company(
         id: $id,
         showLog: $showLog
        ) {
          id
          isRoot
          rpTag {
            code
          }
        }
      }
    `;
    return this.apollo
      .query({
        variables: {
          id,
          showLog: false
        },
        query
      }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listCompanies(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        companies(
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on Company {
              id
              name
              rpTag {
                code
                description
              }
              tin
              birthday
              rpLastUpdated
              created
              updated
              isRoot
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'name:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          max,
          offset,
          sort,
        },
        query
      }).toPromise();
  }

  search(params: CompanySearchParams, opts: ListOpts) {
    const query = gql`
      query searchCompany(
        $name: String!
        $birthday: Long
        $tin: String
        $businessType: BusinessType
        $max: Int!
        $offset: Int!
        $sort: String!
      ) {
        searchCompany(
          input: {
            name: $name,
            birthday: $birthday,
            tin: $tin,
            businessType: $businessType
          },
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          id
          businessType
          name
          remarks
          rpClassification {
            code
            description
          }
          rpTag {
            code
            description
          }
          birthday
          tin
          cif
          logicalBranchCode
          created
          updated
          isRoot
          ocsTag
          icbsTag
          essTag
          rpLastUpdated
          availableSubscribedCapitalStock
          availableOutstandingVotingStock
          aliases {
            ... on Alias {
              name
            }
          }
          # Note 2021/10/18: Do not delete yet until thoroughly tested that removing this does not affect any existing functionalities
          # relationships {
          #   id
          #   destination {
          #     ... on Person {
          #       id
          #       firstName
          #       middleName
          #       lastName
          #     }
          #     ... on Company {
          #       id
          #       name
          #       businessType
          #     }
          #   }
          #   relationship {
          #     id
          #     code
          #     description
          #   }
          #   attributes {
          #     ... on BooleanRelAttr {
          #       key
          #       booleanValue
          #     }
          #     ... on DateTimeRelAttr {
          #       key
          #       dateTimeValue
          #     }
          #     ... on NumberRelAttr {
          #       key
          #       numberValue
          #     }
          #     ... on StringRelAttr {
          #       key
          #       stringValue
          #     }
          #   }
          # }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'id:asc';

    if (opts.max === 0) {
      max = opts.max;
    } else if (opts.max) {
      max = opts.max;
    }

    if (opts.offset) {
      offset = opts.offset;
    }

    if (opts.sort) {
      sort = opts.sort;
    }

    return this.apollo
      .query({
        variables: {
          name: params.name,
          birthday: params.birthday,
          tin: params.tin,
          businessType: $enum(BusinessType).getKeyOrDefault(params.businessType),
          max,
          offset,
          sort
        },
        query
      }).toPromise();
  }

  update(
    company: CompanyModel,
    indAfflList: CustomerIndividualAffiliateModel[],
    nonIndAfflList: CustomerNonIndividualAffiliateModel[],
    aliasList: AliasModel[]
  ) {
    const relationships = [];

    indAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          id: rel.id,
          source: rel.person.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          id: rel.id,
          destination: rel.person.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    nonIndAfflList.forEach(rel => {
      const attributes = [];

      rel.attributes.forEach(attr => {
        attributes.push({
          key: attr.key,
          booleanValue: attr.booleanValue,
          dateTimeValue: attr.dateTimeValue,
          numberValue: attr.numberValue,
          stringValue: attr.stringValue
        });
      });

      if (rel.isSource) {
        relationships.push({
          id: rel.id,
          source: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      } else {
        relationships.push({
          id: rel.id,
          destination: rel.company.id,
          relationship: rel.relationship.id,
          attributes
        });
      }
    });

    const mutation = gql`
      mutation updateCompany(
        $id: ID!
        $name: String!
        $remarks: String
        $tin: String
        $birthday: Long
        $logicalBranchCode: String
        $isNGORetailFinance: Boolean
        $cif: String
        $businessType: BusinessType
        $relationships: [UpdateCustomerRelationship]
        $aliases: [UpdateAliasRequest]
        $isParentOfRoot: Boolean
        $isRelatedParty: Boolean!
        $isRelatedToRoot: Boolean
        $isBSPSupervised: Boolean
      ) {
        updateCompany(
          input: {
            id: $id,
            name: $name,
            remarks: $remarks,
            birthday: $birthday,
            businessType: $businessType,
            logicalBranchCode: $logicalBranchCode,
            isNGORetailFinance: $isNGORetailFinance,
            tin: $tin,
            cif: $cif,
            relationships: $relationships,
            aliases: $aliases,
            isParentOfRoot: $isParentOfRoot,
            isRelatedParty: $isRelatedParty,
            isRelatedToRoot: $isRelatedToRoot
            isRelatedParty: $isRelatedParty
            isBSPSupervised: $isBSPSupervised
          }
        ) {
          id
          name
          remarks
          tin
          cif
          businessType
          logicalBranchCode
          birthday
          isNGORetailFinance
          isParentOfRoot
          isRelatedToRoot
          aliases {
            ... on Alias {
              id
              name
            }
          }
          # 20211020 - Removed since this doesn't need to be returned because the user will be redirected to the index page
          # relationships {
          #   id
          #   destination {
          #     ... on Person {
          #       id
          #       firstName
          #       middleName
          #       lastName
          #     }
          #     ... on Company {
          #       id
          #       name
          #     }
          #   }
          #   relationship {
          #     id
          #     description
          #   }
          # }
          created
          updated
        }
      }
    `;

    return this.apollo
      .mutate({
        variables: {
          id: company.id,
          name: company.name,
          remarks: company.remarks,
          birthday: company.birthday,
          logicalBranchCode: company.logicalBranchCode,
          tin: company.tin,
          cif: company.cif,
          isNGORetailFinance: company.isNGORetailFinance,
          businessType: $enum(BusinessType).getKeyOrDefault(company.businessType),
          relationships,
          aliases: aliasList.map( alias => ({
            name: alias.name
          })),
          isParentOfRoot: company.isParentOfRoot,
          isRelatedParty: company.isRelatedParty,
          isRelatedToRoot: company.isRelatedToRoot,
          isBSPSupervised: company.isBSPSupervised
        },
        mutation
      }).toPromise();
  }
}
