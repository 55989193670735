import { Apollo } from 'apollo-angular';
import { ListOpts } from '../api/list-opts';
import gql from 'graphql-tag';
import { ApplicationModel, CollateralConnectionModel } from './application';
import { OwnershipOption } from './ownership-option';
import { $enum } from 'ts-enum-util';
import { CollateralModel } from './collateral';

export class ApplicationsApi {
  constructor(private apollo: Apollo) {
  }

  // TODO: Improve. Pass only application, collaterals, coOwnerIds
  checkAggregateBreach(
    applicationId: any, borrowerId: any, productTypeId: any, amount: any,
    collaterals: CollateralModel[], coOwnerIds: string[], appRpTagCode: any
  ) {
    const query = gql`
    query checkAggregateBreach(
      $applicationId: ID
      $borrowerId: ID!
      $productTypeId: ID!
      $amount: Float!
      $collaterals: [ListApproversCollateralRequest]
      $coOwnerIds: [ID]
      $appRpTagCode: String!
    ) {
      checkAggregateBreach(
        input: {
          applicationId: $applicationId,
          borrowerId: $borrowerId,
          productTypeId: $productTypeId,
          amount: $amount,
          collaterals: $collaterals,
          coOwnerIds: $coOwnerIds,
          appRpTagCode: $appRpTagCode
        }
      )
      {
        breached
        aggregateAmount
        relationshipToBdo {
          code
        }
        breachedThreshold
        application{
          customer {
            __typename
            ... on Company{
              id
            }
            ... on Person{
              id
            }
          }
          amount
          productType{
            id
          }
        }
        relatedParty
      }
    }
    `;

    return this.apollo.query({
      variables: {
        applicationId,
        borrowerId,
        productTypeId,
        amount,
        collaterals: collaterals.map(col => ({
          ownershipOption: $enum(OwnershipOption).getKeyOrDefault(col.ownershipOption),
          isUndividedInterest: col.isUndividedInterest,
          connections: col.connections.map(con => ({
            id: con.id,
            collateral: con.id,
            customer: con.customerId
          }))
        })),
        coOwnerIds,
        appRpTagCode
      }, query
    }).toPromise();
  }

  // TODO: Improve. Pass only application, collaterals, coOwnerIds
  checkApprovers(
    applicationId: any, appRpTagCode: any, borrowerId: any, productTypeId: any,
    amount: any, coOwnerIds: string[], collaterals: CollateralModel[]
  ) {
    const query = gql`
      query getApprovalMatrix(
        $applicationId: ID
        $appRpTagCode: String!
        $borrowerId: ID!
        $productTypeId: ID!
        $amount: Float!
        $coOwnerIds: [ID]
        $collaterals: [ListApproversCollateralRequest]
      ) {
        getApprovalMatrix(
          input: {
            applicationId: $applicationId,
            appRpTagCode: $appRpTagCode,
            borrowerId: $borrowerId,
            productTypeId: $productTypeId,
            amount: $amount,
            coOwnerIds: $coOwnerIds,
            collaterals: $collaterals
          }
        )
      }
    `;

    return this.apollo.query({
      variables: {
        applicationId,
        appRpTagCode,
        borrowerId,
        productTypeId,
        amount,
        coOwnerIds,
        collaterals: collaterals.map(col => ({
          ownershipOption: $enum(OwnershipOption).getKeyOrDefault(col.ownershipOption),
          isUndividedInterest: col.isUndividedInterest,
          connections: col.connections.map(con => ({
            id: con.id,
            collateral: con.id,
            customer: con.customerId
          }))
        }))
      }, query
    }).toPromise();
  }

  create(
    application: ApplicationModel,
    coOwnerList: CollateralConnectionModel[],
    collaterals: CollateralModel[]
  ) {
    const mutation = gql`
      mutation createApplication(
        $borrowerId: ID!
        $productTypeId: ID!
        $amount: Float!
        $applicationReference: String
        $collateralConnections: [CreateCollateralConnection]
        $collaterals: [CreateCollateralRequest]
        $termLength: Int!
        $termType: String!
        $applicationSourceSystem: String
      ) {
        createApplication(
          input: {
            borrowerId: $borrowerId,
            productTypeId: $productTypeId,
            amount: $amount,
            applicationReference: $applicationReference,
            collaterals: $collaterals,
            collateralConnections: $collateralConnections,
            termLength: $termLength,
            termType: $termType,
            applicationSourceSystem: $applicationSourceSystem
          }
        ) {
          id
          rpTag {
            code
            description
          }
          productType {
            code
            description
            type
          }
          amount
          applicationReference
          term
          applicationSourceSystem
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        borrowerId: application.borrowerId,
        productTypeId: application.productTypeId,
        amount: application.amount,
        applicationReference: application.applicationReference,
        collaterals: collaterals.map(col => ({
          ownershipOption: $enum(OwnershipOption).getKeyOrDefault(col.ownershipOption),
          isUndividedInterest: col.isUndividedInterest,
          connections: col.connections.map(con => con.customerId)
        })),
        collateralConnections: coOwnerList.map(connection => ({
          id: connection.id,
          coOwnerId: connection.coOwnerId
        })),
        termLength: application.termLength,
        termType: application.termType,
        applicationSourceSystem: application.applicationSourceSystem
      },
      mutation
    }).toPromise();
  }

  delete(id: string) {
    const mutation = gql`
      mutation deleteApplication(
        $id: ID!
      ) {
        deleteApplication(
          id: $id
        ) {
          status
          code
          message
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        id
      },
      mutation
    }).toPromise();
  }

  get(id: string) {
    const query = gql`
      query getApplication(
        $id: ID!
      ) {
        application(
          id: $id
        ) {
          id
          created
          updated
          customer {
            ... on Person {
              id
              firstName
              middleName
              lastName
              rpTag {
                code
                description
              }
            }
            ... on Company {
              id
              name
              rpTag {
                code
                description
              }
            }
          }
          rpTag  {
            code
            description
          }
          productType {
            id
            code
            description
            type
          }
          amount
          applicationReference
          collateralConnections {
            ... on CollateralConnection {
              id
              coOwner {
                ... on Company {
                  id
                  name
                  rpTag {
                    code
                    description
                  }
                }
                ... on Person {
                  id
                  firstName
                  middleName
                  lastName
                  rpTag {
                    code
                    description
                  }
                }
              }
            }
          }
          collaterals {
            ... on Collateral {
              id
              application {
                id
              }
              connections {
                ... on CollateralCustomer {
                  id
                  collateral {
                    id
                  }
                  customer {
                    ... on Company {
                      id
                      name
                      rpTag {
                        code
                        description
                      }
                    }
                    ... on Person {
                      id
                      firstName
                      middleName
                      lastName
                      rpTag {
                        code
                        description
                      }
                    }
                  }
                }
              }
              isUndividedInterest
              ownershipOption
              }
            }
          term
          approvalDate
          applicationSourceSystem
        }
      }
    `;

    return this.apollo.query({
      variables: {
        id
      },
      query
    }).toPromise();
  }

  list(opts: ListOpts) {
    const query = gql`
      query listApplications(
        $max: Int!,
        $offset: Int!,
        $sort: String!
      ) {
        applications (
          opts: {
            max: $max,
            offset: $offset,
            sort: $sort
          }
        ) {
          total
          data {
            ... on Application {
              id
              created
              updated
              customer {
                ... on Person {
                  id
                  firstName
                  middleName
                  lastName
                }
                ... on Company {
                  id
                  name
                }
              }
              rpTag  {
                code
                description
              }
              amount
              productType {
                code
                description
                type
              }
              status
            }
          }
        }
      }
    `;

    let max = 10;
    let offset = 0;
    let sort = 'created:desc';

    if (opts.max === 0)
      max = opts.max;
    else if (opts.max)
      max = opts.max;

    if (opts.offset)
      offset = opts.offset;

    if (opts.sort)
      sort = opts.sort;

    return this.apollo.query({
      variables: {
        max,
        offset,
        sort,
      },
      query
    }).toPromise();
  }

  update(
    application: ApplicationModel,
    coOwnerList: CollateralConnectionModel[],
    collateralList: CollateralModel[]
  ) {
    const mutation = gql`
      mutation updateApplication(
        $id: ID!
        $amount: Float!
        $applicationReference: String
        $collaterals: [UpdateCollateralRequest]
        $collateralConnections: [UpdateCollateralConnectionRequest]
        $termLength: Int!
        $termType: String!
        $approvalDate: Int
        $applicationSourceSystem: String
      ) {
        updateApplication (
          input: {
            id: $id,
            amount: $amount,
            applicationReference: $applicationReference,
            collaterals: $collaterals,
            collateralConnections: $collateralConnections,
            termLength: $termLength,
            termType: $termType,
            approvalDate: $approvalDate,
            applicationSourceSystem: $applicationSourceSystem
          }
        ) {
          id
          created
          updated
          amount
          applicationReference
          term
          approvalDate
          applicationSourceSystem
          rpTag {
            code
            description
          }
        }
      }
    `;

    return this.apollo.mutate({
      variables: {
        id: application.id,
        amount: application.amount,
        applicationReference: application.applicationReference,
        collaterals: collateralList.map(collateral => ({
          id: collateral.id,
          isUndividedInterest: collateral.isUndividedInterest,
          ownershipOption: $enum(OwnershipOption).getKeyOrDefault(collateral.ownershipOption),
          connections: collateral.connections.map(connection => ({
            id: connection.id,
            collateral: collateral.id,
            customer: connection.customerId
          }))
        })),
        collateralConnections: coOwnerList.map(connection => ({
          id: connection.id,
          coOwnerId: connection.coOwnerId
        })),
        termLength: application.termLength,
        termType: application.termType,
        approvalDate: application.approvalDate,
        applicationSourceSystem: application.applicationSourceSystem
      },
      mutation
    }).toPromise();
  }
}
